<template>
  <div class="scrollable">
    <dashboard v-if="porte" :independent="porte"></dashboard>
    <div class="spacer"></div>
  </div>
</template>

<script>
import Dashboard from "@/components/Dashboard/Dashboard";
export default {
  name: "AdminDashboardPorte",
  components: {Dashboard},
  data () {
    return {
      porte: null
    }
  },
  async created () {
    this.$store.dispatch('sidebar/changeMenu', 'Portes')
    this.porte = (await this.$store.dispatch('independent/getOneIndependent', this.$route.params.idPorte))
  }
}
</script>

<style scoped>

</style>
